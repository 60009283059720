.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* sidebar ================== */
.css-1wvake5 {
  width: 200px !important;
  min-width: 200px !important;
  border-right-style: none !important;
}

.css-dip3t8 {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: white !important;
  border-radius: 24px;
}
.sidebar-font-menu {
  font-weight: 600;
  color: rgb(125, 133, 146);
  /* display: flex; */
  padding: 0px 5px;
}
.sidebar-font-submenu {
  /* font-weight: 400; */
  color: rgb(159, 165, 175);
}

.css-1wvake5 {
  position: fixed;
}
.css-1t8x7v1 > .ps-menu-button {
  height: 35px !important;
}
.css-1tqrhto > .ps-menu-button {
  height: 35px !important;
  /* font-weight: 400 !important; */
  /* font-size: 14px !important; */
  /* color: rgb(125, 133, 146) !important; */
}
.sidebar-icons {
  font-size: 20px;
}
/* .css-16jesut > .ps-menu-button:hover {
  background-color: #1127a1 !important;
} */
/* dahsbaord======================== */
.headerTitle-0-1-70 {
  color: rgb(10, 22, 41);
  font-size: 36px;
  font-weight: bold;
  line-height: 49px;
}
.headerDesc-0-1-71 {
  gap: 8px;
  color: rgb(125, 133, 146);
  display: flex;
  font-size: 18px;
  align-items: center;
  /* font-weight: bold; */
  font-weight: 600;
  line-height: 26px;
}
.border-left-accent {
  border-left: 3px solid #00007c !important;
}
.card-border-1 {
  border-left: 3px solid #00007c !important;
}
.form-select {
  width: 100% !important;
  border-radius: 5px !important;
  padding: 8px !important;
}
.label {
  color: black;
  font-size: 18px;
  font-weight: bold;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: white !important;
  background-color: blue !important;
}
.css-a1sg1c-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  /* color: rgba(0, 0, 0, 0.26); */
  background-color: blue !important;
  color: white !important;
}
.s_input_value {
  height: 40px;
  border: 1px solid lightgrey;
  outline: none;
  font-size: 14px;
  padding-left: 8px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.modal_close_icon i {
  background-color: #4b006e;
  color: white !important;
  font-size: 11px;
  padding: 8px 10px;
  border-radius: 50px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.poppins-thin {
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", serif;
  font-weight: 900;
  font-style: italic;
}
.strem_button {
  background-color: #4b006e;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
