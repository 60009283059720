.chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .messages {
    flex: 1;
    overflow-y: auto;
  }
  
  .message {
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    max-width: 60%;
  }
  
  .sent {
    background-color: #dcf8c6;
    align-self: flex-end;
  }
  
  .received {
    background-color: #ffffff;
    align-self: flex-start;
  }
  
  .timestamp {
    font-size: 0.8em;
    color: gray;
    margin-top: 5px;
  }
  
  .input-container111 {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  textarea {
    /* flex: 1; */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    /* resize: none; */
  }
  
  button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  