.addCourseMain-0-1-698 {
  width: 100%;
  height: auto;
  border-top: 1px solid #e4e6e8;
  border-radius: 24px;
  background-color: rgba(255, 255, 255);
}
.stepperContainer-0-1-705 {
  border-bottom: 1px solid #e4e6e8;
}
.stepper-0-1-716 {
  width: 100%;
  counter-reset: steps 0;
}
.stepper-0-1-716 .step-0-1-710 {
  margin: 0px;
  display: flex;
  padding: 21px 40px 8px;
}
.stepper-0-1-716 .step-0-1-710 .stepItem-0-1-714:first-child {
  align-items: flex-start;
  justify-content: flex-start;
}
.stepper-0-1-716 .step-0-1-710 .stepItem-0-1-714 {
  flex: 1 1 0%;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
}
.stepper-0-1-716 .step-0-1-710 .stepTitle-0-1-711 {
  font-size: 16px;
  margin-top: 0px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 8px;
}
.stepper-0-1-716 .step-0-1-710 .active .stepTitleText-0-1-715 {
  font-weight: 700;
}
.stepper-0-1-716 .step-0-1-710 .stepItem-0-1-714 .progress {
  top: calc(12px);
  left: calc(50% + 20px);
  width: 0px;
  height: 2px;
  margin: 0px;
  position: absolute;
  transition: all 1s ease 0s;
  border-width: 0px;
  border-radius: 1px;
  background-color: rgb(0, 173, 231);
}
.addCourseSection-0-1-699 {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  padding-top: 24px;
  padding-right: 32px;
  padding-bottom: 24px;
  justify-content: space-between;
}
.addCourseForm-0-1-700 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.sectionContainer-0-1-723 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.marginLeft-0-1-718 {
  margin-left: 32px;
}
.abountInfo-0-1-719 {
  font-size: 16px;
  font-weight: 700;
}
.radioInputTagContainer-0-1-720 {
  gap: 60px;
  display: flex;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  justify-content: flex-start;
}
.radioBtnWrap-0-1-732 {
  padding: 3px 0px;
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
}
.tooltipWrapper-0-1-734 {
  display: inline-Block;
  position: relative;
}
.tooltipTip-0-1-735 {
  left: 50%;
  color: white;
  border: 2px solid #606060;
  padding: 12px 16px 12px 16px;
  z-index: 100;
  position: absolute;
  font-size: 12px;
  max-width: 200px;
  min-width: 200px;
  transform: translateX(-50%);
  background: #333333;
  box-shadow: 0px 6px 12px 0px #00000033;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  line-height: 1.3;
  border-radius: 8px;
}
.stepper-0-1-716 .step-0-1-710 .stepItem-0-1-714:first-child::before {
  margin: 0px 0px 1rem;
}
.stepper-0-1-716 .step-0-1-710 .active::before {
  background: rgba(0, 173, 231, 0.2);
  border: none !important;
}
.stepper-0-1-716 .step-0-1-710 .stepItem-0-1-714:first-child::after {
  left: calc(3% + 20px);
  width: calc(147% - 40px);
}
.css-2royc9 {
  /* right: 0px; */
  right: 10px;
  left: 242px;
  border-top: 1px solid rgb(247, 247, 247);
  border-right: 1px solid rgb(247, 247, 247);
  border-left: 1px solid rgb(247, 247, 247);
  border-image: initial;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding: 20px;
  z-index: 10;
  position: fixed;
  background: rgb(255, 255, 255);
  box-shadow: rgba(196, 203, 214, 0.25) 0px -4px 4px;
  transition: width 0.4s ease 0s;
  margin-right: 24px;
  border-bottom: none;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.basicCardContainer-d0-0-1-728 .content {
  max-height: 5000px;
  margin-top: 8px;
}
.basicCardContainer-0-1-726 .content {
  transition: all 0.5s ease 0s;
}
.headingInfoText-0-1-701 {
  font-size: 16px;
  font-weight: 700;
}
.editInput-0-1-729 {
  display: flex;
  padding: 8px 0;
  font-size: 14px;
  line-height: 24px;
  flex-direction: column;
}
.labelContainer-0-1-730 {
  color: #7d8592;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: space-between;
}
.textAreaDesc-0-1-724 {
  color: #000000 !important;
  height: 110px;
  font-size: 16px;
  font-weight: 600;
}
.textarea-0-1-732 {
  /* color: #7D8592 !important; */
  width: 100%;
  border: 1px solid #d8e0f0;
  outline: none;
  padding: 12px;
  z-index: 0;
  /* font-size: 14px; */
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  margin-top: 0.5rem;
  border-radius: 14px;
}
.DraftEditor-root {
  position: relative;
}
.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.DraftEditor-editorContainer {
  background-color: #fff0;
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}
.public-DraftEditor-content[contenteditable="true"] {
  -webkit-user-modify: read-write-plaintext-only;
}
.textarea-0-1-732 .public-DraftEditor-content {
  padding: 0px !important;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}
.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}
.mentionAbout-0-1-725 {
  color: #7d8592;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}
.labelContainer-0-1-730 {
  color: #7d8592;
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: space-between;
}
.uploadThumbnail-d10-0-1-776 {
  padding: 12px 18px;
  background: rgba(21, 192, 230, 0.1);
}
.buttonHoverClass-d9-0-1-775 {
  width: 260px;
  height: 150px;
}
.uploadThumbnail-d10-0-1-776 span {
  padding-left: 12px;
}
.uploadThumbnail-0-1-738 span {
  color: rgb(21, 192, 230);
  font-size: 12px;
  font-style: normal;
  font-family: "Nunito Sans";
  font-weight: 700;
  line-height: 20px;
}
.recommendedSize-0-1-740 {
  margin-top: 8px;
  align-items: center;
}
.recommendedSize-0-1-740 img {
  flex: 0 0 auto;
  margin-right: 9px;
}
.recommendedSize-0-1-740 span {
  color: rgb(125, 133, 146);
  font-size: 12px;
  font-style: normal;
  font-family: "Nunito Sans";
  font-weight: 400;
  line-height: 20px;
}
.input-0-1-501 {
  width: 50%;
  border: 1px solid rgb(216, 224, 240);
  outline: none;
  padding: 12px 12px 12px 16px;
  font-size: 16px;
  /* font-family: "Nunito Sans"; */
  font-weight: 600;
  line-height: 24px;
  border-radius: 16px;
}
.animated-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.animated-icon {
  animation: bounce 1s infinite alternate; /* Example animation */
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.animated-text {
  animation: bounce 1s infinite alternate;
  /* Define animation for the text */
}
